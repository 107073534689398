

.img-center {
    margin: auto;
    display: block;
}

.uper-section {
    padding: 5px;
    background-color: #f9f9f9;
    margin: 2% 0 5% 0;
}

.rating_text span {
    font-size: 14px;
}

.border {
    border: 1px solid #d9d9d9;
}

.service_section {
    background-color: #fff;
}

.service_switch {
    border: none;
    padding: 6px 15px;
    font-size: 16px;
     
    text-align: center;
    border-radius: 3px;
    background: #fff;
    border: 2px solid #ccc;
    margin: 2px;
}

.services{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}


.phone{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.phone i{
    font-size: 1.8rem;
}

.service_switch_toggle {
    border: 1px solid #000;
    padding: 10px 15px;
    font-weight: 600;
    color: #fff;
   margin: 2px ;
    text-align: center;
    border-radius: 3px;
    background: #000;
}

.services {
    padding: 10px;
    margin: 0 0 0 0;
}

.padding-left-right-3 {
    padding-left: 3px !important;
    padding-right: 3px !important;
}

.services .row {
    margin-bottom: 10px;
}
.center-text {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #9e9e9e;
    line-height: 0.1em;
    margin: 10px 0 20px;
}

    .center-text span {
        background: #eee;
        padding: 0 10px;
        color: #9e9e9e;
    }

.success-box {
    margin: 50px 0;
    padding: 10px 10px;
    border: 1px solid #eee;
    background: #f9f9f9;
}

    .success-box img {
        margin-right: 10px;
        display: inline-block;
        vertical-align: top;
    }
/* 
    #root{
        background-color: #f5f5f5!important;
    } */

    .success-box > div {
        vertical-align: top;
        display: inline-block;
        color: #888;
    }
    .grey.darken-4 {
        background-color: #212121 !important;
    }

    .img_w {
        height: 200px;
        width: 200px !important;
    }
    .img-center {
        margin: auto;
        display: block;
    }
    nav .brand-logo.right {
        right: 0.5rem;
        padding: 0;
    }
    .footer_button {
        position: fixed;
        bottom: 0;
        width: 100%;
        border: none;
        font-size: 16px;
    }
    .btn-large {
        height: 54px;
        line-height: 54px;
    }
    .btn, .btn-large {
        text-decoration: none;
        color: #fff;
        background-color: #26a69a;
        text-align: center;
        letter-spacing: .5px;
        -webkit-transition: .2s ease-out;
        transition: .2s ease-out;
        cursor: pointer;
    }
/* Rating Star Widgets Style */
.rating-stars ul {
list-style-type:none;
padding:0;

-moz-user-select:none;
-webkit-user-select:none;
}
.rating-stars ul > li.star {
display:inline-block;

}

/* Idle State of the stars */
.rating-stars ul > li.star > i.fa {
font-size:2.5em; /* Change the size of the stars */
color:#ccc; /* Color on idle state */
}

/* Hover state of the stars */
.rating-stars ul > li.star.hover > i.fa {
color:#f9f1ab;
}

/* Selected state of the stars */
.rating-stars ul > li.star.selected > i.fa {
color:#FFD700;
}


.feed_check {
    margin: 10px 0 0;
    float: right;
}

.footer_button {
    position: fixed;
    bottom: 0;
    width: 100%;
}

    .footer_button a {
        width: 100%;
        font-size:20px;
    }

    .brand-logo{font-size:1.6rem!important}
    .modal.bottom-sheet{top:0;max-height:100%!important;}
    nav{height:40px;line-height:40px;}
    .footer_button a{height:40px;line-height:40px;}
    .img_w{height:100px;width:200px !important;object-fit: contain;}

    .box-card{
        -webkit-box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%), 0 3px 1px -2px rgb(0 0 0 / 20%); 
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%), 0 3px 1px -2px rgb(0 0 0 / 20%);
    }
