body {
  /* font-family: "Poppins", sans-serif !important; */
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.btn.btn-outline-theme {
  color: #f36f21;
  background-color: transparent;
  border-color: #f36f21;
}
.btn.btn-outline-theme:hover {
  color: #fff;
  background-color: #f36f21;
  border-color: #f36f21;
}

.btn.btn-outline-theme i {
  color: #f36f21;
}

.btn.btn-outline-theme:hover i {
  color: #fff;
}

.section-color-white {
  color: #fff !important;
}

/* Login css starts */
.login-aside {
  background-image: url(../media/bg/bg-9.jpg);
}

.sidebar {
  position: sticky;
  top: 0;
  z-index: 999;
  padding: 10px !important;
  left: 0;
  width: 100%;
}
.login.login-1 .login-signin,
.login.login-1 .login-signup,
.login.login-1 .login-forgot {
  display: none;
}
  .btn-theme2 {
      color: #fff;
      background-color: #dcb086;
      border-color: #cba772;
  }
.login.login-1.login-signin-on .login-signup {
  display: none;
}

.login.login-1.login-signin-on .login-signin {
  display: block;
}

.login.login-1.login-signin-on .login-forgot {
  display: none;
}

.login.login-1.login-signup-on .login-signup {
  display: block;
}

.login.login-1.login-signup-on .login-signin {
  display: none;
}

.login.login-1.login-signup-on .login-forgot {
  display: none;
}

.login.login-1.login-forgot-on .login-signup {
  display: none;
}

.login.login-1.login-forgot-on .login-signin {
  display: none;
}

.login.login-1.login-forgot-on .login-forgot {
  display: block;
}

.homeVoucher .owl-nav .owl-prev,
.homeVoucher .owl-nav .owl-next {
  font-size: 0;
}

.homeVoucher .owl-nav .owl-prev:after,
.homeVoucher .owl-nav .owl-next:after {
  content: "";
  position: absolute;
  top: 0;
  background-image: url(../media/arrow.png);
  width: 30px;
  height: 30px;
  background-size: 30px;
  bottom: 0;
  margin: auto;
}

.homeVoucher .owl-nav .owl-prev:after {
  left: 20%;
  transform: rotate(180deg);
}

.homeVoucher .owl-nav .owl-next:after {
  right: 20%;
  transform: rotate(0deg);
}

.slideVoucher .owl-nav .owl-prev,
.slideVoucher .owl-nav .owl-next {
  font-size: 0;
}

.slideVoucher .owl-nav .owl-prev:after,
.slideVoucher .owl-nav .owl-next:after {
  content: "";
  position: absolute;
  top: 0;
  background-image: url(../media/arrow.png);
  width: 30px;
  height: 30px;
  background-size: 30px;
  bottom: 0;
  margin: auto;
}

.slideVoucher .owl-nav .owl-prev:after {
  left: -30px;
  transform: rotate(180deg);
}

.slideVoucher .owl-nav .owl-next:after {
  right: -30px;
  transform: rotate(0deg);
}

.slideVoucher .owl-nav {
  display: block !important;
}

@media (min-width: 992px) {
  .login.login-1 .login-aside {
    width: 100%;
    max-width: 600px;
  }
  .login.login-1 .login-form {
    width: 100%;
    max-width: 400px;
  }
}

@media (min-width: 992px) and (max-width: 1399.98px) {
  .login.login-1 .login-aside {
    width: 100%;
    max-width: 400px;
  }
}

@media (max-width: 991.98px) {
  .login.login-1 .login-form {
    width: 100%;
    max-width: 350px;
  }
}

@media (max-width: 575.98px) {
  .uniq h2 {
    font-size: 18px;
  }
  .login.login-1 .login-form {
    width: 100%;
    max-width: 100%;
  }

  .productSlides .owl-nav .owl-prev,
  .productSlides .owl-nav .owl-next {
    font-size: 0;
  }

  .productSlides .owl-nav .owl-prev:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    height: 40px;

    width: 40px;
    align-items: center;
    display: flex;
    background-image: url(../media/next.png);
    background-size: 30px;
    background-repeat: no-repeat;
    transform: rotate(-180deg);
    background-color: #fff;
    background-size: 20px;
    border-radius: 50%;
  
    background-position: center;
    box-shadow: 0 7px 5px rgba(0,0,0,0.2);
  }

  .productSlides .owl-nav .owl-next:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 40px;
    background-color: #fff;
    width: 40px;
    align-items: center;
    display: flex;
    background-image: url(../media/next.png);
    background-repeat: no-repeat;
    background-size: 20px;
    border-radius: 50%;
  
    background-position: center;
    box-shadow: 0 7px 5px rgba(0,0,0,0.2);
  }

  .homeVoucher .owl-nav .owl-prev:after {
    left: 10px;
    transform: rotate(180deg);
  }

  .homeVoucher .owl-nav .owl-next:after {
    right: 10px;
    transform: rotate(0deg);
  }

  .slideVoucher .owl-nav {
    display: none !important;
  }

  .probx h4 {
    font-size: 14px;
  }

  .lgnleft {
    width: 100%;
  }

  .voucherSlide .owl-nav {
    display: none;
  }

  .lgnright {
    width: 100%;
  }

  .tophead {
    margin-bottom: 10px;
  }

  .tophead form .subs {
    width: 75%;
  }
  .tophead form .subs input {
    width: 100%;
    height: 40px !important;
  }

  .tophead form .subs {
    margin-bottom: 0;
  }

  .sbmt input {
    margin: 0 10px;
    height: 40px;
    border-radius: 5px !important;
    width: 100%;
  }

  .subs input:focus footer {
    display: none !important;
  }

  .sbmt {
    width: 20%;
  }

  .wishBtn {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 0;
    /* background: #fff; */
    z-index: 9;
    /* border-top: 1px solid #eee; */
  }

  .logo {
    width: 100%;
  }
  .mlogo {
    cursor: pointer !important;
  }

  footer {
    z-index: 999;
  }

  .owl-item{
   cursor: pointer;
  }

  .mainIMage {
    width: 100%;
    margin: auto;
    display: block;
  }

  .wishBtn .btnpad {
    padding: 0 1px;
  }

  .whlistpro h2 {
    font-size: 16px;
  }

  .whlistpro p {
    font-size: 12px;
  }

  .whlistpro .price {
    font-size: 20px;
  }

  .whlistproimg img {
    width: 105px;
    height: 133px;
    object-fit: contain;
  }

  .browsesubmit {
    font-size: 14px;
  }
  .customerDetail {
    width: 100% !important;
    margin: 0 auto;
  }
}

.customerDetail {
  width: 50%;
  margin: 0 auto;
}

.customerDetail img {
  width: 100px !important;
  border-radius: 50px;
  margin: 10px auto;
  height: 100px;
}

.customerDetail .h4 {
  text-align: center !important;
  font-size: 20px !important;
}

.customerDetail p {
  text-align: center !important;
}

/* admin */
.admin.MuiAppBar-root {
  /* box-shadow: none;
    background-color: #!important;
    color:#fff */
}

.admin .MuiPaper-root {
  background-color: #fff;
  /* border-right: 1px dashed #f36f21; */
}

div.logo img {
  width: 200px;
  padding: 10px;
}

.listIcon {
  min-width: 25px !important;
}

.listHeader {
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 30px !important;
  padding: 10px 16px !important;
  background: #f7f7f7 !important;
}

.previewList {
  background: #f7f7f7;
  border-radius: 10px;
  border: 1px solid #eee;
  height: 100%;
  max-height: 300px;
  overflow: auto;
  margin-bottom: 20px;
}

.hide {
  display: none;
}

.text-red {
  color: red !important;
}
.thank_bg {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-image: url(../media/thank.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.thank_bg h1 {
  color: #fff;
  font-size: 80px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.thank_bg .checkIN h3 {
  font-size: 40px;
}

.thank_bg .checkIN {
  height: 120px;
}

.user-card-full {
  overflow: hidden;
}

.card {
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  border: none;
  background-color: rgb(255 255 255 / 75%);
  margin-bottom: 30px;
}

.sms-textbox {
  width: 24%;
  padding: 0 !important;
  border: 0;
  border-bottom: 1px solid #ccc;
  margin: 0 5px;
}

.m-r-0 {
  margin-right: 0px;
}

.m-l-0 {
  margin-left: 0px;
}

.user-card-full .user-profile {
  border-radius: 5px 0 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-c-lite-green {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#f29263),
    to(#ee5a6f)
  );
  background: linear-gradient(to right, #ee5a6f, #f29263);
}

.user-profile {
  padding: 20px 0;
}

.card-block {
  padding: 1.25rem;
}

.m-b-25 {
  margin-bottom: 25px;
}

.img-radius {
  border-radius: 5px;
}

h6 {
  font-size: 14px;
}

.card .card-block p {
  line-height: 25px;
}

@media only screen and (min-width: 1400px) {
  p {
    font-size: 14px;
  }
}

.card-block {
  padding: 1.25rem;
}

.b-b-default {
  border-bottom: 1px solid #e0e0e0;
}

.m-b-20 {
  margin-bottom: 20px;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.card .card-block p {
  line-height: 10px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.text-muted {
  color: #919aa3 !important;
}

.b-b-default {
  border-bottom: 1px solid #e0e0e0;
}

.f-w-600 {
  font-weight: 600;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-t-40 {
  margin-top: 20px;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-t-40 {
  margin-top: 20px;
}

.user-card-full .social-link li {
  display: inline-block;
}

.user-card-full .social-link li a {
  font-size: 20px;
  margin: 0 10px 0 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.exsbmtbtn {
  font-size: 14px;
  width: 25%;
  margin-left: auto;
}

.profileBg {
  background-image: url(../media/home/background1.jpg) !important;
  background-size: cover !important;
  background-position: bottom !important;
}

.video_call .MuiDialog-paperWidthXs {
  max-width: 350px !important;
  background-color: rgb(249, 249, 250);
}

.see_design .MuiDialog-paperWidthXs {
  max-width: 500px !important;
  height: 400px;
  background-color: rgb(249, 249, 250);
}

.seeDesign {
  background-image: url(../media/see_design.jpg);
  background-size: cover;
  position: relative;
}

.seeDesign::before {
  content: "";
  background-color: #000;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.seeDesign button {
  font-size: 16px;
  background-color: #fff;
}

.mobile-input-text {
  background-color: #f3f6f9;
  border-color: #f3f6f9;
  color: #3f4254;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease;
  height: 100%;
  border-radius: 3px;
  border: 1px solid #eee;
  padding: 9px 10px;
  font-weight: 600;
}
.th.sortable {
  color: #000 !important;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: 95% 50%;
  background-size: 16px;
}
.badge-success {
  color: #1bc5bd;
  background-color: #c9f7f5;
  border-radius: 0.42rem;
  height: 24px;
  width: 24px;
  font-size: 0.9rem;
  padding: 0.9rem 0.75rem;
}
.badge-info {
  color: #1976d2;
  background-color: #c8d1d9;
  border-radius: 0.42rem;
  height: 24px;
  width: 24px;
  font-size: 0.9rem;
  padding: 0.9rem 0.75rem;
}
.badge-warning {
  color: #ffa800;
  background-color: #fff4de;
  border-radius: 0.42rem;
  height: 24px;
  width: 24px;
  font-size: 0.9rem;
  padding: 0.9rem 0.75rem;
}
.badge-danger {
  color: #f64e60;
  background-color: #ffe2e5;
  border-radius: 0.42rem;
  height: 24px;
  width: 24px;
  font-size: 0.9rem;
  padding: 0.9rem 0.75rem;
}

.badge-green {
  color: #28a745;
  background-color: #a1ecb2;
  border-radius: 0.42rem;
  height: 24px;
  width: 24px;
  font-size: 0.9rem;
  padding: 0.9rem 0.75rem;
}

.font-size32 {
  font-size: 32px;
}

.pointerShow {
  cursor: pointer;
}
.modal-header > .modal-title {
  width: 100%;
}
.chat-container {
  border: 2px solid #dedede;
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 15px;
  margin: 5px 0;
}
.chat-darker {
  border-color: #ccc;
  background-color: #ddd;
}
.time-right {
  float: right;
  color: #aaa;
}
.chat-text-body {
  max-height: 300px;
  overflow: auto;
}

.custom_dashboard .MuiDialog-root {
  z-index: 99999 !important;
}

.session-table-cust-height {
  max-height: 450px;
  overflow: auto;
}

.BA-Notification-Deactive,
.BA-Notification-Active {
  position: fixed;
  top: 15%;
  width: 70%;
  z-index: 9999;
  background: #fff;
  bottom: 0;
  padding: 2rem;
  right: -250%;

  border-radius: 10px;
  height: 70%;
  overflow-y: auto;
  transition: all 0.4s;
  max-height: 350px;
  /* transform: translate(-150%); */
}
.BA-Notification-Active {
  transform: translate(0);
  right: 0;
  left: 50%;
}

.Black-color-font {
  color: #000;
}

.recentTableHeight {
  max-height: 375px;
}

.clock_container.clock {
  width: 150px;
}

.clock_container .clock_circle {
  padding: 5px;
  background-color: #000;
}

.logo_session img {
  width: 250px;
  height: 100px;
  object-fit: contain;
  cursor: pointer !important;
}

.accordion_panel .panel_heading {
  padding: 0;
  margin-bottom: 8px;
  padding-top: 5px;
}

.product_badge {
  background-color: rgb(242, 222, 188);
  width: auto;
  display: inherit;
  padding: 0 15px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.probximg {
  position: relative;
}

.probximg .product_badge {
  bottom: 10px;
  position: absolute;
}

.product_badge_1 {
  background-color: #ffba3c;
  width: auto;
  display: inline-block;
  padding: 0 15px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.probximg .product_badge_1 {
  bottom: 10px;
  right:0;
  position: absolute;
}

.profile_bg {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  max-width: 700px;
  margin: auto;
}

.profile_bg_image {
  background-image: url(../media/home/background1.jpg) !important;
  background-size: 100% !important;
  background-position: left !important;
}
.profile_bg_image1 {
  background-image: url(../media/home/background1.jpg) !important;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: left !important;
}

.thnkulogo {
  position: absolute;
  width: 200px;
  height: 100px;
  top: 8%;
  bottom: 0;
  margin: auto;
  left: 100px;
}

.thnkutext {
  
}

.awssld__content > img,
.awssld__content > video {
  object-fit: contain !important;
}

.img-pointer{
  cursor: pointer;
}

.owl-item{
  cursor: pointer;
}

.thumb{
  cursor: pointer;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blink {
  animation: blink 1s infinite;
}
