html {
  scroll-behavior: smooth;
}

body {
  background: url(../media/home/background.jpg) top center no-repeat !important;
  /* font-family: "arial"; */
  background-size: 100% 600px !important;
}

h1,
h2,
h3,
h4 {
  /* font-family: "Verdana" !important; */
  margin: 0px;
  padding: 0px;
}
.clr {
  clear: both;
}
.toppad1 {
  padding-top: 10px;
}
.toppad2 {
  padding-top: 15px;
}
.toppad3 {
  padding-top: 20px;
}
.toppad4 {
  padding-top: 25px;
}
.toppad5 {
  padding-top: 30px;
}
.toppad6 {
  padding-top: 35px;
}
.toppad7 {
  padding-top: 40px;
}
.toppad8 {
  padding-top: 45px;
}
.toppad9 {
  padding-top: 50px;
}
.toppad10 {
  padding-top: 55px;
}
.toppad11 {
  padding-top: 60px;
}
.toppad12 {
  padding-top: 65px;
}
.toppad13 {
  padding-top: 70px;
}
.toppad14 {
  padding-top: 80px;
}
.toppad15 {
  padding-top: 90px;
}
.toppadhead {
  padding-top: 5em;
}
.botpad1 {
  padding-bottom: 10px;
}
.botpad2 {
  padding-bottom: 15px;
}
.botpad3 {
  padding-bottom: 20px;
}
.botpad4 {
  padding-bottom: 25px;
}
.botpad5 {
  padding-bottom: 30px;
}
.botpad6 {
  padding-bottom: 35px;
}
.botpad7 {
  padding-bottom: 40px;
}
.botpad8 {
  padding-bottom: 45px;
}
.topmargn3 {
  margin-top: 30px;
}
.topmargn4 {
  margin-top: 40px;
}
.topmargn5 {
  margin-top: 50px;
}
.botmargn1 {
  margin-bottom: 10px;
}
.botmargn2 {
  margin-bottom: 20px;
}
.botmargn3 {
  margin-bottom: 30px;
}
.botmargn4 {
  margin-bottom: 40px;
}
.topmenu {
  background: #ededed;
  text-align: center;
  font-size: 14px;
  padding: 5px 0;
}

.lrpadd {
  padding: 0px;
}
.lpadd1 {
  padding-left: 5px;
}
.lpadd2 {
  padding-left: 10px;
}
.lrpadd60 {
  padding: 0px 60px;
}
section {
  display: block;
  overflow: hidden;
}
.topborder {
  border-top: #e9e7e8 1px solid;
}
.greybg {
  width: 100%;
  background: #fbfbfb;
}
.carousel {
  width: 94%;
  margin: 0 auto;
  transform: translateY(-50%);
  top: 50%;
}
.carousel-item img {
  width: 100%;
}
.main_header_area {
  background: transparent !important;
}
.navbar_fixed {
  background: #fff !important;
}
.navbar_fixed .mlogo {
  height: 30px;
}
.rightnav {
}
.rightnav ul {
  padding: 0px;
  width: 100%;
  float: right;
}
.rightnav ul li {
  display: inline-block;
  float: right;
  padding-left: 16px;
}

.clntlogotxt {
  font-size: 42px;
  color: #ffae01;
  border-bottom: 2px solid #ffae01;
  display: inline-block;
}
.tollno {
  /* font-family: "ElMessiri-Regular"; */
  font-size: 18px;
  float: right;
}
.bdgicon {
  position: relative;
  font-size: 24px;
  top: -8px;
  color: #007bff;
}
.bdg {
  background: #fff;
  border-radius: 50px;
  border: 1px solid #ccc;
  position: absolute;
  top: 5px;
  left: 12px;
  font-size: 12px;
  width: 20px;
  height: 20px;
  text-align: center;
}
.protxt {
  font-size: 24px !important;
  color: #007bff;
}
.callbtn {
  background: transparent;
}
/**.tophead{width:100%; float:left; position: fixed; top:0px;}**/
.tophead {
  width: 100%;
  float: left;
}
.tophead h1 {
  font-size: 42px;
}
.topheadl {
  width: 48%;
  float: left;
  margin-right: 4%;
  margin-top: 46px;
}
.topheadr {
  width: 48%;
  float: left;
}
.imgcurve {
  border-radius: 3px;
}
.customcontainer {
  width: 70%;
  margin: 0 auto;
}
.container70 {
  width: 70%;
  margin: 0 auto;
}
.lgnleft {
  width: 40%;
  float: left;
  padding-right: 15px;
}
.lgnright {
  width: 60%;
  float: right;
}
/*----------form starting stylings-----------*/
.group {
  position: relative;
  width: 250px;
  float: left;
  margin-bottom: 45px;
}
.sbmt {
  width: 76px;
  float: left;
}
.vbotpad5 {
  padding-bottom: 30px !important;
}
/* .owl-carousel{ height: 160px;} */

.voucherSlide .owl-nav > .owl-next {
  top: 22%;
  position: absolute;
  bottom: 0;
  width: 30px;
  height: 30px;
  right: -40px;
  background-image: url(../media/next.png);
  background-size: 30px;
  font-size: 0;
}

.voucherSlide .owl-nav > .owl-prev {
  top: 22%;
  position: absolute;
  bottom: 0;
  width: 30px;
  height: 30px;
  left: -40px;
  background-image: url(../media/next.png);
  background-size: 30px;
  font-size: 0;
  transform: rotate(-180deg);
}

.sbmtbtn {
  background: #ffba3c;
  border: none;
  color: #2d2d2d;
  font-size: 18px;
  padding: 4px 0;
  width: 56%;
  margin-top: 8px;
  margin-left: 4px;
  border-radius: 5px;
}
.sbmtbtn1 {
  background: #ffba3c;
  border: none;
  color: #2d2d2d;
  font-size: 18px;
  padding: 4px 0;
  width: 100%;
  margin-top: 8px;
  margin-left: 4px;
  border-radius: 5px;
}
.subs input {
  font-size: 16px;
  background: transparent;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 250px;
  border: none;
  border-bottom: 1px solid #787c7f;
}
.subs input:focus {
  outline: none;
}
.subs label {
  color: #fff;
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.subs input:focus ~ .subs label,
.subs input:valid ~ .subs label {
  top: -20px;
  font-size: 14px;
  color: #fff;
}
.bar1 {
  width: 100%;
}
.bar .bar1 {
  position: relative;
  display: block;
  width: 250px;
  top: 1px;
}
.bar:before,
.bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #ffba3c;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.bar:before {
  left: 50%;
}
.bar:after {
  right: 50%;
}
.subs input:focus ~ .bar:before,
.subs input:focus ~ .bar:after {
  width: 50%;
}
.highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}
.subs input:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

@-webkit-keyframes inputHighlighter {
  from {
    background: #5264ae;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-moz-keyframes inputHighlighter {
  from {
    background: #5264ae;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@keyframes inputHighlighter {
  from {
    background: #5264ae;
  }
  to {
    width: 0;
    background: transparent;
  }
}

.sbmt1 {
  width: 100%;
}

footer {
  background: #ffba3c;
  padding: 10px;
  color: #2d2d2d;
  font-size: 12px;
}
.custom-footer {
  background: #ffba3c;
  margin-top: 26px;
  padding: 10px;
  color: #2d2d2d;
  font-size: 12px;
  position: fixed;
  bottom: -1px;
  width: 100%;
  z-index: 99;
}

.thumb {
  height: 90px;
  object-fit: contain;
  width: 100%;
  margin-bottom: 10px;
  border: 1px solid #eee;
}

.thumb img {
  height: 90px;
  object-fit: contain;
  width: 90px;
}

.social:hover {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  color: #fff;
}
.social {
  color: #2d2d2d;
  font-size: 22px;
  padding-right: 8px;
  -webkit-transform: scale(0.8);
  /* Browser Variations: */
  -moz-transform: scale(0.8);
  -o-transform: scale(0.8);
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
}

/*********Category Section*******/
.catsection {
  background: url(../media/home/category-bg.jpg) repeat;
}
.catsectionbx {
  width: 97%;
  margin: 0 auto;
}
.uniq h2 {
  font-size: 30px;
  text-align: center;
}
.cat1bx {
}
.cat1bx h3 {
  font-size: 30px;
  color: #ffdf01;
}
.cat1bx h3 span {
  font-size: 40px;
  color: #2d2d2d;
}
.cat1bx p {
  font-size: 16px;
}
.cat2bx {
  background: #fcecea;
  width: 100%;
  height: 236px;
  border: 1px solid #eeeeee;
  padding-left: 20px;
  border-radius: 3px;
  -moz-box-shadow: 2px 2px 3px #888;
  -webkit-box-shadow: 2px 2px 3px #888;
  box-shadow: 2px 2px 3px #888;
}
.catl {
  width: 50%;
  float: left;
  padding-top: 50px;
}
.catr {
  width: 50%;
  height: 235px;
  float: left;
}
.catr img {
  width: 100%;
  height: 100%;
  border-radius: 0px 3px 3px 0px;
}
.catl h3 {
  font-size: 30px;
}
.catl p {
  font-size: 12px;
  color: #828282;
  letter-spacing: 2px;
}
.cat2bx:nth-child(2n + 0) {
  background: #e4fdff;
  width: 100%;
}

.catsectionbx {
  width: 100%;
}

.catsectimg {
  width: 100%;
}

.catsecthumb {
}
.catsecthumb ul {
}
.catsecthumb ul li {
  display: inline-block;
  list-style: none;
}
.catsecthumb ul li a {
}

.prosection {
  background: #fbfbfb;
}
.sortprobx {
  width: 30%;
  float: right;
  margin: 0px;
  padding-right: 15px;
}
.sortpro {
  float: right;
  margin: 0px;
}
.probx {
  background: #fff;
  min-height: 254px;
  padding: 0;
  position: relative;
  border: 2px solid #eee;
  border-radius: 8px;
  overflow: hidden;
}
.probximg img {
  width: 100%;
  height: 230px;
  object-fit: cover;
  display: block;
}
.probx h4 {
  font-size: 18px;
  padding: 6px 0 6px 6px;
}
.probx a {
  color: #2d2d2d;
}
.probx a:hover {
  text-decoration: none;
}
.probx .price {
  font-size: 16px;
  color: #2d2d2d;
  padding-left: 6px;
}
.probx .viewbx {
  width: 100%;
  padding-top: 16px;
}
.probx .viewbx a {
  background: #ffba3c;
  padding: 10px 100px;
  color: #2d2d2d;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.probx .viewbx a:hover {
  background: #ec9f13;
  text-decoration: none;
}

.behclick-panel .list-group {
  margin-bottom: 0px;
}
.behclick-panel .list-group-item:first-child {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.behclick-panel .list-group-item {
  border-right: 0px;
  border-left: 0px;
}
.behclick-panel .list-group-item:last-child {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.behclick-panel .list-group-item {
  padding: 5px;
}
.behclick-panel .panel-heading {
  /* 	padding: 10px 15px;
		border-bottom: 1px solid transparent; */
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom: 1px solid darkslategrey;
}
.behclick-panel .panel-heading:last-child {
  /* border-bottom: 0px; */
}
.behclick-panel {
  border-radius: 0px;
  border-right: 0px;
  border-left: 0px;
  border-bottom: 0px;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
}
.behclick-panel .radio,
.checkbox {
  margin: 0px;
  padding-left: 10px;
}
.behclick-panel .panel-title > a,
.panel-title > small,
.panel-title > .small,
.panel-title > small > a,
.panel-title > .small > a {
  outline: none;
}
.behclick-panel .panel-body > .panel-heading {
  padding: 10px 10px;
}
.behclick-panel .panel-body {
  padding: 0px;
}
.behclick-panel a:link {
  text-decoration: none;
}
.behclick-panel a:visited {
  text-decoration: none;
  font-size: 14px;
}
.behclick-panel a:hover {
  text-decoration: none;
}
.behclick-panel a:active {
  text-decoration: none;
}

.productSlides .owl-carousel {
  box-shadow: 6px 6px 27px rgb(211 211 211 / 31%), -6px -6px 20px #ededed;
  border-radius: 10px;
}

.ac-footer .ac-icon {
  position: absolute;
  right: 15px;
  width: 20px;
  height: 30px;
  z-index: 99;
  top: 15px;
}
.ac-footer .ac-icon-love-dark {
  background: url("../media/home/heart.png") no-repeat center center/contain;
  opacity: 1;

  width: 30px;
  height: 30px;
  background-size: 37px;
  border-radius: 50px;
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(251, 54, 110, 0.4);
  }
  70% {
    box-shadow: 0 0 0 30px rgba(251, 54, 110, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(251, 54, 110, 0);
  }
}

@keyframes pulseHeart {
  0% {
    transform: scale(1.3);
  }
  70% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}
.ac-icon-love-dark {
  display: block;
  position: relative;
}
.ac-icon-love-dark:before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 45%;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  right: 0;
  z-index: -1;
  border-radius: 100%;
}
.ac-footer-container:hover .ac-icon-love-dark {
  animation: pulseHeart 1s ease;
}
.ac-footer-container:hover .ac-icon-love-dark:before {
  animation: pulse 1s ease;
}
.ac-icon-triangle {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #788795;
  position: relative;
  display: inline-block;
  margin-left: 8px;
}
.ac-icon-love-dark.active {
  background: url("../media/home/icon-heart-light-fc92b1e0.svg") no-repeat
    center center/contain;
  opacity: 1;
  cursor: pointer;
  opacity: 1;

  width: 30px;
  height: 30px;
  background-size: 20px;
  border-radius: 50px;
}

/************Testimonial**********/

.testimonial-section2 {
  position: relative;
  padding: 40px 0 20px 0;
}
.testim .wrap {
  position: relative;
  width: 100%;
  max-width: 1020px;
  padding: 15px 20px;
  margin: auto;
}
.testim .arrow {
  display: block;
  position: absolute;
  color: #eee;
  cursor: pointer;
  font-size: 2em;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 5px;
  z-index: 22222222;
}
.testim .strcentr {
  text-align: center;
  font-size: 24px;
  margin-bottom: 10px;
}
.testim .arrow:before {
  cursor: pointer;
}
.testim .arrow:hover {
  color: #2b288d;
}
.testim .arrow.left {
  left: 10px;
}
.testim .arrow.right {
  right: 10px;
}
.testim .dots {
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: 60px;
  left: 0;
  display: block;
  z-index: 3333;
  height: 12px;
}
.testim .dots .dot {
  list-style-type: none;
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid #c6c4c4;
  margin: 0 10px;
  cursor: pointer;
  -webkit-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: relative;
}
.testim .dots .dot.active,
.testim .dots .dot:hover {
  background: #2b288d;
  border-color: #2b288d;
}
.testim .dots .dot.active {
  -webkit-animation: testim-scale 0.5s ease-in-out forwards;
  -moz-animation: testim-scale 0.5s ease-in-out forwards;
  -ms-animation: testim-scale 0.5s ease-in-out forwards;
  -o-animation: testim-scale 0.5s ease-in-out forwards;
  animation: testim-scale 0.5s ease-in-out forwards;
}

.testim .cont {
  position: relative;
  overflow: hidden;
}
.testim .cont > div {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0px;
  opacity: 0;
}
.testim .cont > div.inactive {
  opacity: 1;
}
.testim .cont > div.active {
  position: relative;
  opacity: 1;
}
.testim .cont div .img img {
  display: block;
  width: 100px;
  height: 100px;
  margin: auto;
  border-radius: 50%;
}
.testim .cont div .h4 {
  color: #2b288d;
  font-size: 1.2em;
  margin: 15px 0;
}
.testim .cont div p {
  font-size: 1.15em;
  color: #2d2d2d;
  width: 80%;
  margin: auto;
}
.testim .cont div.active .img img {
  -webkit-animation: testim-show 0.5s ease-in-out forwards;
  -moz-animation: testim-show 0.5s ease-in-out forwards;
  -ms-animation: testim-show 0.5s ease-in-out forwards;
  -o-animation: testim-show 0.5s ease-in-out forwards;
  animation: testim-show 0.5s ease-in-out forwards;
}

.testim .cont div.active .h4 {
  -webkit-animation: testim-content-in 0.4s ease-in-out forwards;
  -moz-animation: testim-content-in 0.4s ease-in-out forwards;
  -ms-animation: testim-content-in 0.4s ease-in-out forwards;
  -o-animation: testim-content-in 0.4s ease-in-out forwards;
  animation: testim-content-in 0.4s ease-in-out forwards;
}

.testim .cont div.active p {
  -webkit-animation: testim-content-in 0.5s ease-in-out forwards;
  -moz-animation: testim-content-in 0.5s ease-in-out forwards;
  -ms-animation: testim-content-in 0.5s ease-in-out forwards;
  -o-animation: testim-content-in 0.5s ease-in-out forwards;
  animation: testim-content-in 0.5s ease-in-out forwards;
}

.testim .cont div.inactive .img img {
  -webkit-animation: testim-hide 0.5s ease-in-out forwards;
  -moz-animation: testim-hide 0.5s ease-in-out forwards;
  -ms-animation: testim-hide 0.5s ease-in-out forwards;
  -o-animation: testim-hide 0.5s ease-in-out forwards;
  animation: testim-hide 0.5s ease-in-out forwards;
}

.testim .cont div.inactive .h4 {
  -webkit-animation: testim-content-out 0.4s ease-in-out forwards;
  -moz-animation: testim-content-out 0.4s ease-in-out forwards;
  -ms-animation: testim-content-out 0.4s ease-in-out forwards;
  -o-animation: testim-content-out 0.4s ease-in-out forwards;
  animation: testim-content-out 0.4s ease-in-out forwards;
}

.testim .cont div.inactive p {
  -webkit-animation: testim-content-out 0.5s ease-in-out forwards;
  -moz-animation: testim-content-out 0.5s ease-in-out forwards;
  -ms-animation: testim-content-out 0.5s ease-in-out forwards;
  -o-animation: testim-content-out 0.5s ease-in-out forwards;
  animation: testim-content-out 0.5s ease-in-out forwards;
}

@-webkit-keyframes testim-scale {
  0% {
    -webkit-box-shadow: 0px 0px 0px 0px #eee;
    box-shadow: 0px 0px 0px 0px #eee;
  }

  35% {
    -webkit-box-shadow: 0px 0px 10px 5px #eee;
    box-shadow: 0px 0px 10px 5px #eee;
  }

  70% {
    -webkit-box-shadow: 0px 0px 10px 5px #ea830e;
    box-shadow: 0px 0px 10px 5px #ea830e;
  }

  100% {
    -webkit-box-shadow: 0px 0px 0px 0px #ea830e;
    box-shadow: 0px 0px 0px 0px #ea830e;
  }
}

@-moz-keyframes testim-scale {
  0% {
    -moz-box-shadow: 0px 0px 0px 0px #eee;
    box-shadow: 0px 0px 0px 0px #eee;
  }

  35% {
    -moz-box-shadow: 0px 0px 10px 5px #eee;
    box-shadow: 0px 0px 10px 5px #eee;
  }

  70% {
    -moz-box-shadow: 0px 0px 10px 5px #ea830e;
    box-shadow: 0px 0px 10px 5px #ea830e;
  }

  100% {
    -moz-box-shadow: 0px 0px 0px 0px #ea830e;
    box-shadow: 0px 0px 0px 0px #ea830e;
  }
}

@-ms-keyframes testim-scale {
  0% {
    -ms-box-shadow: 0px 0px 0px 0px #eee;
    box-shadow: 0px 0px 0px 0px #eee;
  }

  35% {
    -ms-box-shadow: 0px 0px 10px 5px #eee;
    box-shadow: 0px 0px 10px 5px #eee;
  }

  70% {
    -ms-box-shadow: 0px 0px 10px 5px #ea830e;
    box-shadow: 0px 0px 10px 5px #ea830e;
  }

  100% {
    -ms-box-shadow: 0px 0px 0px 0px #ea830e;
    box-shadow: 0px 0px 0px 0px #ea830e;
  }
}

@-o-keyframes testim-scale {
  0% {
    -o-box-shadow: 0px 0px 0px 0px #eee;
    box-shadow: 0px 0px 0px 0px #eee;
  }

  35% {
    -o-box-shadow: 0px 0px 10px 5px #eee;
    box-shadow: 0px 0px 10px 5px #eee;
  }

  70% {
    -o-box-shadow: 0px 0px 10px 5px #ea830e;
    box-shadow: 0px 0px 10px 5px #ea830e;
  }

  100% {
    -o-box-shadow: 0px 0px 0px 0px #ea830e;
    box-shadow: 0px 0px 0px 0px #ea830e;
  }
}

@keyframes testim-scale {
  0% {
    box-shadow: 0px 0px 0px 0px #eee;
  }

  35% {
    box-shadow: 0px 0px 10px 5px #eee;
  }

  70% {
    box-shadow: 0px 0px 10px 5px #ea830e;
  }

  100% {
    box-shadow: 0px 0px 0px 0px #ea830e;
  }
}

@-webkit-keyframes testim-content-in {
  from {
    opacity: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-moz-keyframes testim-content-in {
  from {
    opacity: 0;
    -moz-transform: translateY(100%);
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
}

@-ms-keyframes testim-content-in {
  from {
    opacity: 0;
    -ms-transform: translateY(100%);
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

@-o-keyframes testim-content-in {
  from {
    opacity: 0;
    -o-transform: translateY(100%);
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes testim-content-in {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes testim-content-out {
  from {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@-moz-keyframes testim-content-out {
  from {
    opacity: 1;
    -moz-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    opacity: 0;
    -moz-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@-ms-keyframes testim-content-out {
  from {
    opacity: 1;
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    opacity: 0;
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@-o-keyframes testim-content-out {
  from {
    opacity: 1;
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@keyframes testim-content-out {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(-100%);
  }
}

@-webkit-keyframes testim-show {
  from {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-moz-keyframes testim-show {
  from {
    opacity: 0;
    -moz-transform: scale(0);
    transform: scale(0);
  }

  to {
    opacity: 1;
    -moz-transform: scale(1);
    transform: scale(1);
  }
}

@-ms-keyframes testim-show {
  from {
    opacity: 0;
    -ms-transform: scale(0);
    transform: scale(0);
  }

  to {
    opacity: 1;
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

@-o-keyframes testim-show {
  from {
    opacity: 0;
    -o-transform: scale(0);
    transform: scale(0);
  }

  to {
    opacity: 1;
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes testim-show {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@-webkit-keyframes testim-hide {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  to {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

@-moz-keyframes testim-hide {
  from {
    opacity: 1;
    -moz-transform: scale(1);
    transform: scale(1);
  }

  to {
    opacity: 0;
    -moz-transform: scale(0);
    transform: scale(0);
  }
}

@-ms-keyframes testim-hide {
  from {
    opacity: 1;
    -ms-transform: scale(1);
    transform: scale(1);
  }

  to {
    opacity: 0;
    -ms-transform: scale(0);
    transform: scale(0);
  }
}

@-o-keyframes testim-hide {
  from {
    opacity: 1;
    -o-transform: scale(1);
    transform: scale(1);
  }

  to {
    opacity: 0;
    -o-transform: scale(0);
    transform: scale(0);
  }
}

@keyframes testim-hide {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0);
  }
}

@media all and (max-width: 300px) {
  body {
    font-size: 14px;
  }
}

@media screen and (min-width: 991px) {
  .main-div1 {
    width: 50% !important;
    margin-top: 50px !important;
  }
}

@media screen and (max-width: 500px) {
  .testim .arrow {
    font-size: 1.5em;
  }

  .testim .cont div p {
    line-height: 25px;
  }

  .thumb {
    height: 60px;
    object-fit: contain;
    width: 60px;
    margin: 0 10px;
  }

  .thumb img {
    height: 60px;
  }

  .custom-banner {
    width: 100%;
    height: 155px !important;
    object-fit: cover !important;
  }
  .custom-banner1 {
    height: 165px !important;
    object-fit: cover !important;
  }
  .voucher-box .item .item-left {
    display: flex;
    align-items: center;
  }
  .profile_bg_image {
    background-size: cover !important;
  }
}

/*********Product View Section*******/
.prv {
  padding-left: 30px;
}
.prv h2 {
  font-size: 22px;
  color: #2d2d2d;
}
.prv h2 span {
  font-size: 14px;
  /* font-family: arial; */
  font-weight: normal;
}
.prv h2 span a {
  color: #ffba3b;
}
.prv h2 span a:hover {
  color: #ffba3b;
}

.prv p {
  font-size: 14px;
  color: #828282;
}
.prv .price {
  font-size: 26px;
  font-weight: bold;
}
.prv .price span {
  color: #46a506;
}

.prv .product-available {
  font-weight: bold;
  font-size: 16px;
}

.greytxt {
  color: #c5c5c5 !important;
  font-size: 14px;
}
label {
  font-size: 14px;
}
.form-control {
  font-size: 14px;
}
.nav-pills .nav-link {
  border-radius: 0px;
  color: #828282;
  margin-right: 2px;
}
.nav-pills .nav-link:hover {
  color: #ffb30f;
  border-bottom: 2px solid #ffb30f;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent;
  border-bottom: 2px solid #ffb30f;
  color: #ffb30f;
}
.tab-content {
  color: #828282;
  font-size: 14px;
  line-height: 22px;
}

.prvdtl h3 {
  font-size: 22px;
  color: #ffba3b;
}
.prvdtl h5 {
  font-size: 14px;
  font-weight: 600;
}
.prvdtl ul {
  margin: 0px;
  padding: 0;
  font-size: 14px;
}
.prvdtl ul li {
  list-style: none;
  line-height: 24px;
}
.prvdtl p {
  font-size: 14px;
  line-height: 24px;
}
.btnpad:nth-child(odd) {
  padding-right: 8px;
}
.btnpad:nth-child(even) {
  padding-left: 8px;
}

.allhead {
  padding: 5px 0px 20px 0px;
}
.allhead h2 {
  font-size: 22px;
}
/*********Wishlist Section*******/
.MuiTabs-root {
  margin-bottom: 15px;
}

.MuiTabs-root span {
  font-size: 14px;
}
.MuiTabs-flexContainerVertical .MuiTab-root {
  border-bottom: 1px solid #ccc;
}

.MuiTabs-flexContainerVertical .MuiTab-root:last-of-type {
  border-bottom: none;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: #ffb30f !important;
}
.whlist {
  border: 1px solid #e2e2e2;
}
.whlisthead {
  border-bottom: 1px solid #e2e2e2;
  padding: 10px;
}
.whlistpro {
  border-bottom: 1px solid #e2e2e2;
  padding: 2px 10px;
  position: relative;
}
.whlistproimg {
  width: 113px;
  height: 133px;
}
.whlistproimg img {
  width: 113px;
  height: 133px;
  object-fit: cover;
}
.whlistpro h2 {
  font-size: 20px;
  color: #2d2d2d;
}
.whlistpro p {
  font-size: 14px;
  color: #828282;
  margin-bottom: 8px;
}
.whlistpro .price {
  font-size: 26px;
  color: #2d2d2d;
  font-weight: bold;
}
.whlistpro .price span {
  color: #46a506;
}
.whlistpro .closebtn {
  position: absolute;
  bottom: 1px;
  right: 18px;
}
.whlistpro .closebtn a {
  color: #9e9c9c;
}
.whlistpro .closebtn a:hover {
  color: #191919;
}
.ribbon-wrapper-green {
  width: 85px;
  height: 88px;
  overflow: hidden;
  position: absolute;
  top: -3px;
  right: -3px;
}
.ribbon-green {
  font: bold 15px Sans-Serif;
  color: #eb414f;
  text-align: center;
  text-shadow: rgba(255, 255, 255, 0.5) 0px 1px 0px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  position: relative;
  padding: 7px 0;
  left: -5px;
  top: 15px;
  width: 120px;
  background-color: #f87984;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f87984),
    to(#f87984)
  );
  background-image: -webkit-linear-gradient(top, #f87984, #f87984);
  background-image: -moz-linear-gradient(top, #f87984, #f87984);
  background-image: -ms-linear-gradient(top, #f87984, #f87984);
  background-image: -o-linear-gradient(top, #f87984, #f87984);
  color: #fff;
  -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
}
.ribbon-green:before,
.ribbon-green:after {
  content: "";
  border-top: 3px solid #6e8900;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  position: absolute;
  bottom: -3px;
}
.ribbon-green:before {
  left: 0;
}
.ribbon-green:after {
  right: 0;
}
​ .prcedbx {
  width: 80%;
  margin: 0 auto;
  text-align: center;
}
.secbdr {
  margin-top: 63px;
  margin-bottom: 20px;
}
.secbdrin {
  border: 1px solid #d5d3d4;
  padding: 15px 15px;
  display: table;
}

.upldbx {
}
.upldbx h2 {
  font-size: 22px;
  text-align: center;
}
.fileUpload input[type="file"] {
  display: none;
}
.browsebtn {
  background: url(../media/home/uploadimg.jpg);
  cursor: pointer;
  color: #333;
  border: 1px solid #e6e6e6;
  border-radius: 3px;
  width: 90px;
  height: 107px;
}

.browsesubmit {
  background: #ffba3c !important;
  width: 100%;
  text-align: center;
  border: 1px solid #e99906;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.browsesubmit:hover {
  background: #ec9f13;
  text-decoration: none;
}

.addsubmit {
  background: #ffba3c;
  width: 66px;
  text-align: center;
  border: 1px solid #e99906;
  border-radius: 3px;
  padding: 12px 0px;
  margin-top: 40px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.addsubmit:hover {
  background: #ec9f13;
  text-decoration: none;
}
.addsubmit .fa {
  font-size: 28px;
}

/*********Thank you*******/
.thnku {
  
  width: 816px;
  margin: 0 auto;
  height: 456px;
}

.thnkulogo img {
  width: 100%;
}

.thnkutext h2 {
  color: #ffae01;
  font-size: 40px;
  border-bottom: 1px solid #ffae01;
  display: inline-block;
  margin-bottom: 16px;
}

.sidebar {
  background: #fff;
  width: 100%;
  position: sticky;
  position: -webkit-sticky;
  float: left;
  top: 1px;
  z-index: 9999;
}

/*********All Buttons*******/
.leftbdr {
  border-left: 1px solid #fff;
}
.fltrbtn {
  background: #ffba3b;
  border: 0px;
  border-radius: 0px;
  width: 100%;
  padding: 10px 0px;
}
.fltrbtn:focus {
  border: 0px;
  border-radius: 0px;
  outline: 0px;
}

.allbtn {
  background: #ffba3b;
  border: 1px;
  border-radius: 5px;
  width: 100%;
  padding: 10px 0px;
}
.allbtn:focus {
  outline: 0px;
}

.trybtn {
  background: #f87984;
  color: #fff;
  border: 1px;
  border-radius: 5px;
  width: 100%;
  padding: 10px 0px;
}
.trybtn:focus {
  outline: 0px;
}

.suggestbtn {
  background: #ffba3b;
  border: 0px;
  border-radius: 5px;
  border: 1px solid #e99906;
  padding: 2px 4px;
  font-size: 16px;
}
.suggestbtn:focus {
  border: 0px;
  border-radius: 0px;
  outline: 0px;
}

/*********All Buttons Finish*******/

#vantagens {
  background-color: #f9f9f9;
}

.lib-row {
  width: 100%;
}
.card-text {
  position: initial;
}
.lib-panel {
  margin-bottom: 20px;
}
.lib-panel img {
  width: 100%;
  background-color: transparent;
}
.lib-panel .row,
.lib-panel .col-md-6 {
  margin: 0;
  padding: 0;
  background-color: #ffffff;
}
.lib-panel .lib-row.lib-header {
  background-color: #ffffff;
  font-size: 20px;
  padding: 10px 0px 0 0px;
}
.lib-panel .lib-row.lib-header .lib-header-seperator {
  height: 2px;
  width: 26px;
  background-color: #2caffd;
  margin: 7px 0 7px 0;
}
.lib-panel .lib-row.lib-desc {
  position: relative;
  height: auto;
  display: block;
  font-size: 13px;
}
.lib-panel .lib-row.lib-desc a {
  position: absolute;
  width: 100%;
  bottom: 10px;
  left: 20px;
}
.row-margin-bottom {
  margin-bottom: 20px;
}
.box-shadow {
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

/*******voucher*********/
.voucher-box {
  width: 100%;
}
.voucher-box .item {
  width: 100%;
  height: 100px;
  background-color: #fafafa;
  border-radius: 8px;
  position: relative;
  float: left;
  margin-right: 20px;
  margin-top: 16px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
}

.voucher-box .item .circle-top {
  top: -7px;
}
.voucher-box .item .circle-bottom,
.voucher-box .item .circle-top {
  width: 14px;
  height: 14px;
  background-color: #ffff;
  border-radius: 14px;
  position: absolute;
  left: 131px;
  z-index: 10;
}

.voucher-box .item .circle-bottom {
  bottom: -7px;
  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.voucher-box .item .circle-bottom,
.voucher-box .item .circle-top {
  width: 14px;
  height: 14px;
  background-color: #ffff;
  border-radius: 14px;
  position: absolute;
  left: 131px;
  z-index: 10;
}

.voucher-box .item .item-left {
  width: 138px;
  height: 100px;
  padding: 0 10px;
  position: relative;
  float: left;
  overflow: hidden;
  border-right: 1px dashed #eaeaea;
}
.voucher-box .item .item-left .price-style {
  width: 117px;
  position: absolute;
  top: 32px;
  height: 46px;
  display: table-cell;
  color: #c80f0f;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.voucher-box .item .item-left .price-desc {
  font-size: 16px;
  font-weight: 700;
  vertical-align: top;
}
.voucher-box .item .item-left .price {
  font-size: 15px;
  font-weight: 700;
}

.voucher-box .item .item-right {
  float: left;
  padding: 29px 0 0 20px;
}

.voucher-box .item .item-right .p-one {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  width: 100%;
  height: 22px;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 485px;
}
.voucher-box .item .item-right .p-two {
  margin-top: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #666;
}

/*********Mobile*******/
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .slideVoucher .owl-nav .owl-next:after {
    content: none;
  }

  .rightnav ul li:nth-child(3) {
    transform: translate(30px);
  }

  .slideVoucher .owl-nav .owl-prev:after,
  .slideVoucher .owl-nav .owl-next:after {
    content: none !important;
  }
  .mcntr {
    text-align: center;
  }
  .mtoppad3 {
    padding-top: 20px;
  }
  .group {
    width: 250px;
    margin-bottom: 0px;
  }
  .subs input {
    width: 250px;
  }
  .sbmt {
    width: 76px;
  }
  .sbmtbtn {
    width: 100%;
  }
  .bdgicon {
    left: -39px;
  }
  .bdg {
    top: 0px;
    left: 20px;
  }
  .probx {
    background: #fff;
    min-height: 254px;
  }
  .probximg {
    text-align: center;
    position: relative;
    overflow: hidden;
    min-height: 180px;
    max-height: 180px;
  }
  .probximg img {
    max-width: 100%;
    height: 180px;
  }
  .probx .viewbx a {
    padding: 10px 50px;
  }
  .thnku {
    background: transparent;
    width: 100%;
  }
  .thnkulogo {
    width: 100%;
    float: left;
    text-align: center;
    padding-top: 2em;
    padding-left: 0;
  }
  .thnkulogo img {
    width: 40%;
  }
  .thnkutext {
    width: 100%;
    float: left;
    text-align: center;
    padding-top: 2em;
    padding-left: 0;
  }
  .thnkutext h2 {
    color: #ffae01;
    font-size: 40px;
    border-bottom: 1px solid #ffae01;
    display: inline-block;
    margin-bottom: 16px;
  }

  /*********Category Section*******/
  .uniq h2 {
    font-size: 34px;
  }
}

@media only screen and (min-device-width: 600px) and (max-device-width: 767px) {
  .mcntr {
    text-align: center;
  }
  .mtoppad3 {
    padding-top: 20px;
  }
  .group {
    width: 250px;
    margin-bottom: 0px;
  }
  .subs input {
    width: 250px;
  }
  .sbmt {
    width: 76px;
  }
  .sbmtbtn {
    width: 100%;
  }
  .bdgicon {
    left: -39px;
  }
  .bdg {
    top: 24px;
    left: 40px;
  }
  .probx {
    background: #fff;
    min-height: 254px;
  }
  .probximg {
    text-align: center;
    position: relative;
    overflow: hidden;
    min-height: 180px;
    max-height: 180px;
  }
  .probximg img {
    max-width: 100%;
    height: 180px;
  }
  .probx .viewbx a {
    padding: 10px 50px;
  }
  .thnku {
    background: transparent;
    width: 100%;
  }
  .thnkulogo {
    width: 100%;
    float: left;
    text-align: center;
    padding-top: 2em;
    padding-left: 0;
  }
  .thnkulogo img {
    width: 70%;
  }
  .thnkutext {
    width: 100%;
    float: left;
    text-align: center;
    padding-top: 2em;
    padding-left: 0;
  }
  .thnkutext h2 {
    color: #ffae01;
    font-size: 40px;
    border-bottom: 1px solid #ffae01;
    display: inline-block;
    margin-bottom: 16px;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 599px) {
  .toppadhead {
    padding-top: 4em;
  }
  .topmargn3 {
    margin-top: 20px;
  }
  .botmargn3 {
    margin-bottom: 20px;
  }
  .botpad8 {
    padding-bottom: 30px;
  }
  .mcntr {
    text-align: center;
  }
  .mtoppad2 {
    padding-top: 10px;
  }
  .mtoppad3 {
    padding-top: 20px;
  }
  .mtoppad0 {
    padding-top: 0px;
  }
  .rightnav ul {
    width: 100%;
    margin: 0px;
  }
  .group {
    width: 250px;
    margin-bottom: 0px;
  }
  .subs input {
    width: 250px;
  }
  .sbmt {
    width: 76px;
  }
  .sbmtbtn {
    width: 100%;
  }
  .bdgicon {
    left: 0px;
  }
  .bdg {
    top: 6px;
    left: 12px;
  }
  .probxpad:nth-child(odd) {
    padding-left: 8px;
  }
  .probxpad:nth-child(even) {
    padding-right: 8px;
  }
  .probx {
    background: #fff;
    min-height: 254px;
  }
  .probximg {
    text-align: center;
    position: relative;
    overflow: hidden;
    min-height: 180px;
    max-height: 180px;
  }
  .probximg img {
    max-width: 100%;
    height: 180px;
  }
  .probx .viewbx a {
    padding: 10px 50px;
  }

  .thnku {
    background: transparent;
    width: 100%;
  }
  .thnkulogo {
    width: 100%;
    float: left;
    text-align: center;
    padding-top: 2em;
    padding-left: 0;
  }
  .thnkulogo img {
    width: 70%;
  }
  .thnkutext {
    width: 100%;
    float: left;
    text-align: center;
    padding-top: 2em;
    padding-left: 0;
  }
  .thnkutext h2 {
    color: #ffae01;
    font-size: 40px;
    border-bottom: 1px solid #ffae01;
    display: inline-block;
    margin-bottom: 16px;
  }
  /*********Category Section*******/
  .catsectionbx {
    width: 100%;
  }
  .uniq h2 {
    font-size: 22px;
  }
  .cat2bx {
    height: 136px;
    padding-left: 12px;
  }
  .catr {
    height: 135px;
  }
  .catr img {
    height: 135px;
  }
  .catl h3 {
    font-size: 18px;
  }

  /***Filter**/
  .fltrmodal-dialog {
    margin: 0px;
    height: 100% !important;
  }
  .fltrmodal-content {
    width: 100%;
    border-radius: 0px;
    overflow: auto;
    height: 100% !important;
    position: relative;
  }
  .fltrmodal-body {
    padding: 0px;
    position: relative;
  }
  .tab {
    float: left;
    border: 1px solid #ccc;
    background-color: #f1f1f1;
    width: 40%;
    height: 100%;
  }
  .tab button {
    display: block;
    background-color: inherit;
    color: black;
    padding: 10px 16px;
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    transition: 0.3s;
    font-size: 14px;
  }
  .tab button:hover {
    background-color: #ddd;
  }
  .tab button.active {
    background-color: #ccc;
  }
  .tabcontent {
    float: left;
    padding: 0px 12px;
    width: 60%;
    border-left: none;
    height: 100%;
  }
  .list-group-item {
    border: 0px;
    padding: 0.75rem 0.25rem;
  }
  .aplybutnbx {
    width: 100%;
    position: absolute;
    bottom: 0px;
  }
  .aplybutn {
  }
  .fltrclose {
    float: left;
    background: transparent;
    border: 0px;
  }
  .fltrclr {
    float: right;
    background: transparent;
    border: 0px;
    margin-right: 10px;
  }

  .modal.fade:not(.in).bottom .modal-dialog {
    -webkit-transform: translate3d(0, 125%, 0);
    transform: translate3d(0, 125%, 0);
  }
}

@media screen and (min-width: 360px) and (max-width: 600px) {
  .allbtn,
  .trybtn {
    font-size: 14px;
  }
  .fltrbtn {
    font-size: 14px;
  }
  
}

@media only screen and (min-device-width: 500px) {
  
  .thnkutext h2 {
    color: #ffae01;
    font-size: 60px !important;
    border-bottom: 1px solid #ffae01;
    display: inline-block;
    margin-bottom: 16px;
  }
  .thnkuTagline {
    font-style: italic;
    font-size: 20px;
    text-align: center;
    padding-left:15%;
    padding-right:15%;
   }

   .slidePic {
    width: 350px !important;
     height: 100px !important;
      border: 1px solid #ddd
   }
}


@media only screen and (min-device-width: 361px) and (max-device-width: 480px) {
  .slidePic {
    width: 350px !important;
     height: 170px !important;
      border: 1px solid #ddd
   }
 .thnkuTagline {
  font-style: italic;
 }

  .toppadhead {
    padding-top: 4em;
  }
  .topmargn3 {
    margin-top: 20px;
  }
  .botmargn3 {
    margin-bottom: 20px;
  }
  .botpad8 {
    padding-bottom: 16px;
  }
  .mcntr {
    text-align: center;
  }
  .mtoppad0 {
    padding-top: 0px;
  }
  .mtoppad1 {
    padding-top: 5px;
  }
  .mtoppad2 {
    padding-top: 10px;
  }
  .mtoppad3 {
    padding-top: 20px;
  }
  .mtoppad4 {
    padding-top: 30px;
  }
  .mtoppad5 {
    padding-top: 40px;
  }
  .m15 {
    margin: 15px;
  }
  .lgnleft {
    width: 100%;
    padding-right: 0px;
  }
  .lgnright {
    width: 100%;
  }
  .rightnav ul {
    width: 100%;
    margin: 0px;
  }
  .group {
    width: 212px;
    margin-bottom: 0px;
  }
  .subs input {
    width: 212px;
  }
  .sbmt {
    width: 76px;
  }
  .sbmtbtn {
    width: 100%;
  }
  .bdgicon {
    left: 0px;
  }
  .bdg {
    top: 6px;
    left: 12px;
  }
  .probxpad:nth-child(odd) {
    padding-left: 8px;
  }
  .probxpad:nth-child(even) {
    padding-right: 8px;
  }

  .probx {
    background: #fff;
    min-height: 254px;
  }
  .probximg {
    text-align: center;
    position: relative;
    overflow: hidden;
    min-height: 180px;
    max-height: 180px;
  }
  .probximg img {
    max-width: 100%;
    height: 180px;
  }
  .probx .viewbx a {
    padding: 10px 50px;
  }

  .thnku {
    background: transparent;
    width: 100%;
  }
  .thnkulogo {
    width: 100%;
    float: left;
    text-align: center;
    padding-top: 2em;
    padding-left: 0;
  }
  .thnkulogo img {
    width: 70%;
  }
  .thnkutext {
    width: 100%;
    float: left;
    text-align: center;
    padding-top: 2em;
    padding-left: 0;
  }
  .thnkutext h2 {
    color: #ffae01;
    font-size: 30px;
    border-bottom: 1px solid #ffae01;
    display: inline-block;
    margin-bottom: 16px;
  }


  /*********Category Section*******/

  .uniq h2 {
    font-size: 22px;
  }
  .cat2bx {
    height: 136px;
    padding-left: 12px;
  }
  .catr {
    height: 135px;
  }
  .catr img {
    height: 135px;
  }
  .catl h3 {
    font-size: 18px;
  }

  /***Offer**/
  .allhead {
    padding: 0px 0px 12px 0px;
  }
  .allhead h2 {
    font-size: 18px;
  }

  /*****Product View****/
  .prv {
    padding-left: 15px;
  }
  .prv h2 {
    font-size: 18px;
  }
  .prvbdr {
    border: #d5d3d4 1px solid;
    -moz-box-shadow: 1px 1px 16px #d6d4d4;
    -webkit-box-shadow: 1px 1px 16px #d6d4d4;
    box-shadow: 1px 1px 16px #d6d4d4;
  }
  .prv .price {
    font-size: 22px;
    font-weight: bold;
  }
  label {
    font-size: 12px;
  }
  .form-control {
    font-size: 14px;
  }

  /***Filter**/
  .fltrmodal-dialog {
    margin: 0px;
    height: 100% !important;
  }
  .fltrmodal-content {
    width: 100%;
    border-radius: 0px;
    overflow: auto;
    height: 100% !important;
    position: relative;
  }
  .fltrmodal-body {
    padding: 0px;
    position: relative;
  }
  .tab {
    float: left;
    border: 1px solid #ccc;
    background-color: #f1f1f1;
    width: 40%;
    height: 100%;
  }
  .tab button {
    display: block;
    background-color: inherit;
    color: black;
    padding: 10px 16px;
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    transition: 0.3s;
    font-size: 14px;
  }
  .tab button:hover {
    background-color: #ddd;
  }
  .tab button.active {
    background-color: #ccc;
  }
  .tabcontent {
    float: left;
    padding: 0px 12px;
    width: 60%;
    border-left: none;
    height: 100%;
  }
  .list-group-item {
    border: 0px;
    padding: 0.75rem 0.25rem;
  }
  .aplybutnbx {
    width: 100%;
    position: absolute;
    bottom: 0px;
  }
  .aplybutn {
  }
  .fltrclose {
    float: left;
    background: transparent;
    border: 0px;
  }
  .fltrclr {
    float: right;
    background: transparent;
    border: 0px;
    margin-right: 10px;
  }

  .modal.fade:not(.in).bottom .modal-dialog {
    -webkit-transform: translate3d(0, 125%, 0);
    transform: translate3d(0, 125%, 0);
  }

  /******Wish List Section*******/
  .whlistpro h2 {
    font-size: 16px;
    color: #2d2d2d;
  }
  .whlistpro p {
    font-size: 12px;
    color: #828282;
  }
  .whlistpro .price {
    font-size: 21px;
    color: #2d2d2d;
    font-weight: bold;
  }
  .browsebtn {
    width: 101px;
  }
  .addsubmit {
    margin: 0px;
    width: 56px;
    padding: 6px 0px;
    float: right;
  }
  /******refral Section*******/

  .container70 {
    width: 100%;
  }
  .group1 {
    width: 100%;
    margin-bottom: 0px;
  }
  .subs1 input {
    width: 100%;
  }
  .sbmt1 {
    width: 100%;
  }

  /*******voucher*********/
  .voucher-box .item {
    width: 100%;
  }
  .voucher-box .item .item-right .p-one {
    width: 100%;
    height: 10px;
    overflow: visible;
  }
}

@media only screen and (min-device-width: 321px) and (max-device-width: 360px) {
  .toppadhead {
    padding-top: 4em;
  }
  .topmargn3 {
    margin-top: 20px;
  }
  .botmargn3 {
    margin-bottom: 20px;
  }
  .botpad8 {
    padding-bottom: 30px;
  }
  .mcntr {
    text-align: center;
  }
  .mtoppad2 {
    padding-top: 10px;
  }
  .mtoppad3 {
    padding-top: 20px;
  }
  .mtoppad0 {
    padding-top: 0px;
  }
  .rightnav ul {
    width: 100%;
    margin: 0px;
  }
  .group {
    width: 250px;
    margin-bottom: 0px;
  }
  .subs input {
    width: 250px;
  }
  .sbmt {
    width: 76px;
  }
  .sbmtbtn {
    width: 100%;
  }
  .bdgicon {
    left: 0px;
  }
  .bdg {
    top: 6px;
    left: 12px;
  }
  .probxpad:nth-child(odd) {
    padding-left: 8px;
  }
  .probxpad:nth-child(even) {
    padding-right: 8px;
  }
  .probx {
    background: #fff;
    min-height: 254px;
  }
  .probximg {
    text-align: center;
    position: relative;
    overflow: hidden;
    min-height: 180px;
    max-height: 180px;
  }
  .probximg img {
    max-width: 100%;
    height: 180px;
  }
  .probx .viewbx a {
    padding: 10px 50px;
  }

  .thnku {
    background: transparent;
    width: 100%;
  }
  .thnkulogo {
    width: 100%;
    float: left;
    text-align: center;
    padding-top: 2em;
    padding-left: 0;
  }
  .thnkulogo img {
    width: 70%;
  }
  .thnkutext {
    width: 100%;
    float: left;
    text-align: center;
    padding-top: 2em;
    padding-left: 0;
  }
  .thnkutext h2 {
    color: #ffae01;
    font-size: 40px;
    border-bottom: 1px solid #ffae01;
    display: inline-block;
    margin-bottom: 16px;
  }
  /*********Category Section*******/
  .catsectionbx {
    width: 100%;
  }
  .uniq h2 {
    font-size: 22px;
  }
  .cat2bx {
    height: 136px;
    padding-left: 12px;
  }
  .catr {
    height: 135px;
  }
  .catr img {
    height: 135px;
  }
  .catl h3 {
    font-size: 18px;
  }

  /***Filter**/
  .fltrmodal-dialog {
    margin: 0px;
    height: 100% !important;
  }
  .fltrmodal-content {
    width: 100%;
    border-radius: 0px;
    overflow: auto;
    height: 100% !important;
    position: relative;
  }
  .fltrmodal-body {
    padding: 0px;
    position: relative;
  }
  .tab {
    float: left;
    border: 1px solid #ccc;
    background-color: #f1f1f1;
    width: 40%;
    height: 100%;
  }
  .tab button {
    display: block;
    background-color: inherit;
    color: black;
    padding: 10px 16px;
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    transition: 0.3s;
    font-size: 14px;
  }
  .tab button:hover {
    background-color: #ddd;
  }
  .tab button.active {
    background-color: #ccc;
  }
  .tabcontent {
    float: left;
    padding: 0px 12px;
    width: 60%;
    border-left: none;
    height: 100%;
  }
  .list-group-item {
    border: 0px;
    padding: 0.75rem 0.25rem;
  }
  .aplybutnbx {
    width: 100%;
    position: absolute;
    bottom: 0px;
  }
  .aplybutn {
  }
  .fltrclose {
    float: left;
    background: transparent;
    border: 0px;
  }
  .fltrclr {
    float: right;
    background: transparent;
    border: 0px;
    margin-right: 10px;
  }

  .modal.fade:not(.in).bottom .modal-dialog {
    -webkit-transform: translate3d(0, 125%, 0);
    transform: translate3d(0, 125%, 0);
  }
}

@media only screen and (min-device-width: 0px) and (max-device-width: 320px) {
  .toppadhead {
    padding-top: 4em;
  }
  .topmargn3 {
    margin-top: 20px;
  }
  .botmargn3 {
    margin-bottom: 20px;
  }
  .botpad8 {
    padding-bottom: 30px;
  }
  .mcntr {
    text-align: center;
  }
  .mtoppad2 {
    padding-top: 10px;
  }
  .mtoppad3 {
    padding-top: 20px;
  }
  .mtoppad0 {
    padding-top: 0px;
  }
  .rightnav ul {
    width: 100%;
    margin: 0px;
  }
  .group {
    width: 250px;
    margin-bottom: 0px;
  }
  .subs input {
    width: 250px;
  }
  .sbmt {
    width: 76px;
  }
  .sbmtbtn {
    width: 100%;
  }
  .bdgicon {
    left: 0px;
  }
  .bdg {
    top: 6px;
    left: 12px;
  }
  .probxpad:nth-child(odd) {
    padding-left: 8px;
  }
  .probxpad:nth-child(even) {
    padding-right: 8px;
  }
  .probx {
    background: #fff;
    min-height: 254px;
  }
  .probximg {
    text-align: center;
    position: relative;
    overflow: hidden;
    min-height: 180px;
    max-height: 180px;
  }
  .probximg img {
    max-width: 100%;
    height: 180px;
  }
  .probx .viewbx a {
    padding: 10px 50px;
  }

  .thnku {
    background: transparent;
    width: 100%;
  }
  .thnkulogo {
    width: 100%;
    float: left;
    text-align: center;
    padding-top: 2em;
    padding-left: 0;
  }
  .thnkulogo img {
    width: 70%;
  }
  .thnkutext {
    width: 100%;
    float: left;
    text-align: center;
    padding-top: 2em;
    padding-left: 0;
  }
  .thnkutext h2 {
    color: #ffae01;
    font-size: 40px;
    border-bottom: 1px solid #ffae01;
    display: inline-block;
    margin-bottom: 16px;
  }
  /*********Category Section*******/
  .catsectionbx {
    width: 100%;
  }
  .uniq h2 {
    font-size: 22px;
  }
  .cat2bx {
    height: 136px;
    padding-left: 12px;
  }
  .catr {
    height: 135px;
  }
  .catr img {
    height: 135px;
  }
  .catl h3 {
    font-size: 18px;
  }

  /***Filter**/
  .fltrmodal-dialog {
    margin: 0px;
    height: 100% !important;
  }
  .fltrmodal-content {
    width: 100%;
    border-radius: 0px;
    overflow: auto;
    height: 100% !important;
    position: relative;
  }
  .fltrmodal-body {
    padding: 0px;
    position: relative;
  }
  .tab {
    float: left;
    border: 1px solid #ccc;
    background-color: #f1f1f1;
    width: 40%;
    height: 100%;
  }
  .tab button {
    display: block;
    background-color: inherit;
    color: black;
    padding: 10px 16px;
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    transition: 0.3s;
    font-size: 14px;
  }
  .tab button:hover {
    background-color: #ddd;
  }
  .tab button.active {
    background-color: #ccc;
  }
  .tabcontent {
    float: left;
    padding: 0px 12px;
    width: 60%;
    border-left: none;
    height: 100%;
  }
  .list-group-item {
    border: 0px;
    padding: 0.75rem 0.25rem;
  }
  .aplybutnbx {
    width: 100%;
    position: absolute;
    bottom: 0px;
  }
  .aplybutn {
  }
  .fltrclose {
    float: left;
    background: transparent;
    border: 0px;
  }
  .fltrclr {
    float: right;
    background: transparent;
    border: 0px;
    margin-right: 10px;
  }

  .modal.fade:not(.in).bottom .modal-dialog {
    -webkit-transform: translate3d(0, 125%, 0);
    transform: translate3d(0, 125%, 0);
  }
}

/* ------- custom ---------------- */

.img-100 {
  max-width: 100px !important;
}

/* .owl-item{
  margin: 0.5% !important;
} */

.color-red {
  color: red;
}

.filterOption i {
  font-size: 13px;
}

.filterOption span {
  font-size: 14px;
  padding-left: 5px;
  text-transform: capitalize;
}
.filterOption.active i {
  color: #ffba3b;
}
.filterOption.active span {
  font-weight: 600;
}

/* Devender Css 25/08/2021 */
.login-form {
  width: 100%;
  max-width: 500px;
  margin: auto;
}

.exhibitionbg {
  display: flex;
  align-items: center;
  height: 100%;
}

.login-form .form-control.custom {
  border: none;
  border-bottom: 2px solid #888;
  border-radius: 0;
  font-size: 15px;
}

.btn-theme {
  background-color: #ffba3c !important;
  border: 1px solid #ffba3c !important;
  color: #fff !important;
}

.filter_date_range p {
  font-size: 13px;
}

.filter_date_range .f_data_range {
  font-size: 13px;
  background-color: #fff;
  padding: 8px 10px;
  border-radius: 3px;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid #eee;
}

.clock_container {
  /* background-color: #000; */
  height: 100%;
  border-radius: 3px;
  border: 1px solid #fff7f7;
  padding-bottom: 0px;
  padding: 0 10px;
  width: 60%;
}

.clock_container p {
  color: #fff;
  font-size: 11px;
  /* background: #fff7f7; */
  border-radius: 2px;
}

.clock_container h2 {
  font-size: 24px;
  color: #444;
  background-color: #fff7f7;
  width: 44px;
  border-radius: 50px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle_box .time_text {
  font-size: 13px;
  color: #fff;
}

.clock_container p.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 68px;
  white-space: nowrap;
}

.dashboard_top_bar {
  margin-top: 4%;
  margin-bottom: 1%;
  background: #fff;
  padding: 10px 10px;
  border-radius: 10px;
}

.bday_table table thead th {
  color: #d32f2f;
  border-bottom: 2px solid #d32f2f;
}

.pointerShow .card {
  border-radius: 20px;
  border-bottom: 3px solid #ccc;
}

.pointerShow .card h4 {
  font-size: 17px;
}

.pointerShow .card b {
  font-size: 35px;
}

/* .row .pointerShow:nth-child(odd) .card{
  background-color: #ddd;
} */

.notification_modal {
  position: fixed;
  top: 0;
  width: 400px;
  height: 50%;
  z-index: 98;
  overflow: auto;
  right: 0%;
  background: #fff;
  border-radius: 10px;
  top: 12%;
  display: none;
}

.notification_modal.active {
  display: block;
}

.notification_modal > p {
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid #ccc;
  position: sticky;
  top: 0;
  background: #fff;
}

.notification_modal .notification_icon {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  margin-right: 10px;
}

.notification_modal .notification_box {
  padding: 5px 10px;
  border-bottom: 1px solid #eee;
  margin-bottom: 5px;
  display: flex;
}

.notification_modal .notification p {
  margin-bottom: 0;
  font-weight: 500;
}

.notification_modal .notification span {
  font-size: 13px;
}

.recent_customer {
  border: 1px solid #d41c24;
  padding: 0px 8px;
  font-size: 16px;
  width: 25px;
  height: 28px;
  display: inline-block;
  margin-right: 8px;
  border-radius: 3px;
}

.recentTableHeight table tbody tr th span {
  display: inline-block;
  margin-right: 8px;
}

.custom-banner {
  width: 100%;
  height: 320px;
  object-fit: cover;
}

.tag_footer{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.customcontainer.tag_wrapper {
  height: calc(100vh - 45px);
}
/* .customcontainer.tag_wrapper .logo_session img {
  height: 65px;
} */


.gnrl_wrap{
  height: calc(100vh - 195px);
}
.gnrl_wrap .tophead img{
  max-height: 100%;
}
.gnrl_wrap .tophead h4{
  font-size: 15px;
}
.gnrl_wrap .tophead h1 {
  font-size: 25px;
  line-height: 1;
}


@media screen and (max-height: 865px) {
  .topheadl img, .topheadr img{
    height: 220px;
    width: auto !important;
  }
  .lgnleft,.lgnright{
    width: 50%;
  }
 
}