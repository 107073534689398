body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; */
    font-family: 'Lato', sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@font-face {
  font-family: "Verdana";
  src: url(./_assets/fonts/ElMessiri-Regular.eot); /* IE9 Compat Modes */
  src: url(./_assets/fonts/ElMessiri-Regular.eot?#iefix) format("embedded-opentype"), /* IE6-IE8 */
  url("./_assets/fonts/ElMessiri-Regular.otf") format("opentype"), /* Open Type Font */
    url("./_assets/fonts/ElMessiri-Regular.svg") format("svg"), /* Legacy iOS */
    url("./_assets/fonts/ElMessiri-Regular.ttf") format("truetype"), /* Safari, Android, iOS */
    url("./_assets/fonts/ElMessiri-Regular.woff") format("woff"), /* Modern Browsers */
    url("./_assets/fonts/ElMessiri-Regular.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}