@media print {
 
  html,
  body {

      /* Hide the whole page */
      display: none;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.checkinOptions {
  position: absolute;
  bottom: 5%;
  z-index: 99;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
}

.main {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-family: 'Playfair Display', serif!important; */
  font-family: "Urbanist", sans-serif !important;
  font-weight: 700 !important;
}

.checkIN {
  background-image: url(./_assets/media/pattern.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 1.5rem 2rem;
  width: 48%;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkinOptions .checkIN:first-of-type {
  width: 58%;
}

.checkinOptions .checkIN:last-of-type {
  width: 40%;
}

.checkIN img {
  width: 30px;
  height: 30px;
  position: relative;
}

.checkIN,
.checkIN h3,
.checkIN p {
  position: relative;
}

.checkIN h3 {
  font-size: 24px;
}
.checkIN p {
  font-size: 18px;
}
.checkIN::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(255 235 183 / 60%);
  width: 100%;
  height: 100%;
}

.checkIN.white::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(255 255 255 / 70%);
  width: 100%;
  height: 100%;
}

.redeemPanel {
  position: absolute;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  width: 100%;
  bottom: 40%;
}

.redeemOptions {
  background-image: url(./_assets/media/pattern.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 1rem;
  width: 170px;
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;
}

.redeemOptions::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(255 235 183 / 60%);
  width: 100%;
  height: 100%;
}

.redeemOptions h3 {
  font-size: 18px;
  margin: 0;
  position: relative;
  text-align: center;
}

.theme-input.MuiFormControl-root .MuiInput-input {
  font-size: 1.7rem;
}

.theme-input.MuiFormControl-root .MuiInputAdornment-root p {
  font-size: 1.7rem;
  font-weight: 600;
}

.theme-input.MuiFormControl-root .MuiFilledInput-root:hover {
  background-color: rgb(58 43 43 / 4%) !important;
}

.custom_modal .MuiPaper-rounded {
  /* border-radius: 15px!important; */
  padding: 10px;
  border: 3px solid #ddd;
}

.dialog-border {
  border: 3px solid #ccc;
}

.custom_modal .MuiPaper-root {
  background-color: rgb(255 255 255 / 90%) !important;
}

.font-style-1 {
  /* font-family: 'Playfair Display', serif!important; */
  font-family: "Urbanist", sans-serif !important;
}

.checkMobileField img {
  width: 30px;
  margin-right: 20px;
  height: 30px;
}

.theme-input label {
  font-size: 16px;
}

.theme-input input,
.theme-input textarea,
.theme-input .MuiFilledInput-root .MuiSelect-root {
  font-size: 18px;
}

.areaBtn {
  font-weight: 600;
  height: 40px;
  width: 40px;
  min-width: 40px !important;
}
.areaBtn > span {
  font-size: 20px;
}

.theme-input .MuiFilledInput-root {
  background-color: #fff;
  border: 1px solid #ccc;
}

.previewImage img {
  width: 100%;
  height: 208px;
  object-fit: contain;
}

.customerDetails p {
  font-size: 14px;
}

.as-react-table table {
  background-color: #fff;
}

.notificationBtn {
  position: absolute;
  z-index: 9;
  top: 3%;
  right: 3%;
}

.notificationBtn > span {
  background: #fce8b4;
}

.notificationBtn > span i {
  font-size: 22px;
}

.mobileChip {
  font-size: 16px !important;
}

.custom_modal .MuiDialog-paper {
  max-width: 850px !important;
}

.customerLogo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  z-index: 9;
}

.customerLogo img {
  width: 180px;
  height: 180px;
  object-fit: contain;
}
.exp_date {
  color: red;
}
@media only screen and (min-width: 1024px) {
  .checkinOptions {
    width: 80%;
    margin: auto;
  }
  .redeemPanel {
    bottom: 40%;
  }
}

@media only screen and (min-width: 768px) {
  .checkinOptions {
    width: 95%;
    margin: auto;
  }

  .redeemPanel {
    bottom: 20%;
  }
}

@media only screen and (max-width: 600px) {
  .checkinOptions {
    width: 100%;
    margin: auto;
    display: block;
    padding: 1rem;
    bottom: 0;
  }

  .checkIN {
    width: 100% !important;
    margin-bottom: 10px;
    padding: 1rem 1.5rem;
  }

  .MuiDialogContent-root {
    padding: 15px !important;
  }

  .theme-input.MuiFormControl-root .MuiInput-input {
    font-size: 1.3rem;
  }

  .theme-input.MuiFormControl-root .MuiInputAdornment-root p {
    font-size: 1.3rem;
    font-weight: 600;
  }
}

