/* Specific CSS for the flip effect within the ProductCard component */
.product-card {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-block: 14px;
    height: 336.5px;
    perspective: 1000px;
    cursor: pointer;
  }
  
  .product-card .card-inner {
    width: 100%;
    height: 100%;
    transition: transform 0.5s;
    transform-style: preserve-3d;
  }
  
  .product-card.flipped .card-inner {
    transform: rotateY(180deg);
  }
  
  .product-card .card-front,
  .product-card .card-back {
    width: 100%;
    height: 100%;
    position: absolute;
    display: none;
    /* backface-visibility: hidden; */
  }
  
  .product-card .card-front {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
  }
  
  .product-card .card-back {
    transform: rotateY(180deg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #e0e0f0;
  }
  
  .product-card .card img {
    max-width: 100%;
    max-height: 100%;
  }
  
  .card-front .text-container {
    width: 100%;
    text-align: center;
    font-weight: 600;
    padding: 4px;
  }